function downloadBulkAmount(targetElem, amount) {
  // What url are we downloading for?
  let href = $(targetElem).attr('href').split('?')[0];
  if (!amount) {
    // Normalize the amount.
    amount = 1;
  }
  // Update the href (i.e. url) to pass in the amount.
  $(targetElem).attr('href', href + '?amount=' + amount);
  // Log this download in GA.
  if (typeof ga === 'function') {
    ga('send', 'pageview', href + '-file?amount=' + amount);
  }
}

function getAmount(amountElem) {
  let amount = amountElem.val();
  if (!amount) {
    amount = 1;
  }
  return amount;
}

function doBulk(amountElem, pathVersion, resultElem) {
  let amount = getAmount(amountElem);
  let path = '/' + pathVersion + '/bulk.json?amount=' + amount;
  $.ajax(path).then((res) => {
    appendUuids(resultElem, res);
  });
  if (typeof ga === 'function') {
    ga('send', 'pageview', path);
  }
}

function appendUuids(resultElem, uuids) {
  resultElem.empty();
  uuids.forEach(uuid => resultElem.append('<p>' + uuid + '</p>'));
}

$().ready(function () {

  // Handle the return/enter key in the input field.
  $('#amount1').keypress((evt) => {
    if (evt.which === 13) {
      doBulk($('#amount1'), 'version1', $('#version1-bulk-result'));
      return false;
    }
  });
  $('#amount4').keypress((evt) => {
    if (evt.which === 13) {
      doBulk($('#amount4'), 'version4', $('#version4-bulk-result'));
      return false;
    }
  });
  $('#amount7').keypress((evt) => {
    if (evt.which === 13) {
      doBulk($('#amount7'), 'version7', $('#version7-bulk-result'));
      return false;
    }
  });
  $('#amountnil').keypress((evt) => {
    if (evt.which === 13) {
      doBulk($('#amountnil'), 'version-nil', $('#version-nil-bulk-result'));
      return false;
    }
  });
  $('#amountguid').keypress((evt) => {
    if (evt.which === 13) {
      doBulk($('#amountguid'), 'guid', $('#version-guid-bulk-result'));
      return false;
    }
  });

  // Handle the 'generate' button clicks.
  $('#amount1-btn').click(() => {
    doBulk($('#amount1'), 'version1', $('#version1-bulk-result'));
  });
  $('#amount4-btn').click(() => {
    doBulk($('#amount4'), 'version4', $('#version4-bulk-result'));
  });
  $('#amount7-btn').click(() => {
    doBulk($('#amount7'), 'version7', $('#version7-bulk-result'));
  });
  $('#amountnil-btn').click(() => {
    doBulk($('#amountnil'), 'version-nil', $('#version-nil-bulk-result'));
  });
  $('#amountguid-btn').click(() => {
    doBulk($('#amountguid'), 'guid', $('#version-guid-bulk-result'));
  });

  // Handle the 'download to file' links.
  $('#amount1-file').click((evt) => {
    downloadBulkAmount(evt.currentTarget, $('#amount1').val());
  });
  $('#amount4-file').click((evt) => {
    downloadBulkAmount(evt.currentTarget, $('#amount4').val());
  });
  $('#amount7-file').click((evt) => {
    downloadBulkAmount(evt.currentTarget, $('#amount7').val());
  });
  $('#amountnil-file').click((evt) => {
    downloadBulkAmount(evt.currentTarget, $('#amountnil').val());
  });
  $('#amountguid-file').click((evt) => {
    downloadBulkAmount(evt.currentTarget, $('#amountguid').val());
  });

  // Handle the click on the 'copy' button in the jumbotron.
  $('#copy-button').click(function () {
    var element = $('#generated-uuid');
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
    $('#copy-button-text').text('Copied!');
  });
});
